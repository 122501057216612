import { Injectable, signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  showLoadingSig = signal<boolean>(false)
  constructor() { }

  updateLoading(value: boolean): void {
    this.showLoadingSig.set(value)
  }

}
